<template>
  <div class="mt-6 mb-base">
    <template v-if='hasLoaded'>
      <div v-if="form.isGoogleRegistered" class="flex flex-wrap justify-between items-center mb-8">
        <div>
          <p>You are connected to google: </p>
          <p class="font-medium mb-4">{{form.googleEmail}}</p>
        </div>
        <vs-button @click="handleUnlinkGoogleAccount" type="border" color="danger">Remove</vs-button>
      </div>

      <div v-if="!form.isGoogleRegistered" class="flex flex-wrap justify-between items-center mb-8">
          <vs-button @click="handleGoogleSignIn" color="#00aaff" icon-pack="feather" icon="icon-google" class="mb-6 ml-auto">Connect To Google</vs-button>
      </div>

      <div v-if="form.isFacebookRegistered" class="flex flex-wrap justify-between items-center mb-8">
        <div>
          <p>You are connected to facebook: </p>
          <p class="font-medium mb-4">{{form.facebookName}}</p>
        </div>
        <vs-button @click="handleUnlinkFbAccount" type="border" color="danger">Remove</vs-button>
      </div>

      <div v-if="!form.isFacebookRegistered">
        <div style="display: none">
          <v-facebook-login 
            @login='handleFbLogin'
            v-model="fbSdk.model"
            v-bind:app-id="facebookAppId"
            @sdk-init="handleFbSdkInit"
          ></v-facebook-login>
        </div>
        <vs-button 
          @click="handleLinkFbAccount" color="#3d5afe" icon-pack="feather" icon="icon-facebook" class="mb-6 ml-auto">Connect To Facebok
        </vs-button>
      </div>
      
      <div v-if="form.isTwitterRegistered" class="flex flex-wrap justify-between items-center mb-8">
        <div>
          <p>You are connected to twitter: </p>
          <p class="font-medium mb-4">{{form.twitterHandle}}</p>
        </div>
        <vs-button @click="handleUnlinkTwitterAccount" type="border" color="danger">Remove</vs-button>
      </div>
      <div v-if="!form.isTwitterRegistered" class="flex flex-wrap justify-between items-center mb-8">
          <vs-button @click="handleTwitterSignIn" color="#00aaff" icon-pack="feather" icon="icon-twitter" class="mb-6 ml-auto">Connect To Twitter</vs-button>
      </div>
    </template>    
  </div>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import { Form } from '@/core-modules/form-framework/Form.js'
export default {
  components: {
    VFacebookLogin
  },
  mixins:[TpNotificationMixin],
  data () {
    return {
      fbSdk: {
        FB: {},
        model: {},
        scope: {}
      },
      form: new Form({
        isFacebookRegistered: false,
        facebookName: '',
        isGoogleRegistered: false,
        googleEmail: '',
        isTwitterRegistered: false,
        twitterHandle: '', 
      }),
      hasLoaded: false,
      facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
      google: {
        isInit: false,
        isSignIn: false
      }
    }
  },
  mounted () {
    this.$http.get("api/accounts/linkAccounts")
      .then(response => {
          this.form = Object.assign(this.form, response.data)
          this.hasLoaded = true
      })

      // Google auth
      let that = this
      let checkGauthLoad = setInterval(function () {
        that.google.isInit = that.$gAuth.isInit
        that.google.isSignIn = that.$gAuth.isAuthorized
        if(that.isInit) clearInterval(checkGauthLoad)
      }, 1000);
  },
  methods: {
    handleFbSdkInit({ FB, scope }) {
      this.fbSdk.FB = FB
      this.fbSdk.scope = scope
    },
    handleLinkFbAccount () {
      //  We need the access token response so user must login
      if (this.fbSdk.scope.connected) this.fbSdk.scope.logout();
      // This will trigge the onFacebookLogin
      this.fbSdk.scope.login();
    },
    handleUnlinkFbAccount () {
      this.$vs.loading();
      this.$http.delete('api/accounts/linkAccounts/facebook')
        .then(response => {
          this.form.isFacebookRegistered = false;
          this.form.facebookName = '';
          this.$vs.notify({
            title: 'Success',
            text: 'Facebook account unlinked',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 4000
          })
        })
        .catch(errors => {
          this.$vs.notify({
            title: 'Failed',
            text: `Unable to unlink facebook account`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        })
        .finally(()=> this.$vs.loading.close());
    },
    handleFbLogin (response) {
      if (!response || response.status !== 'connected') {
        return;
      }
      this.$vs.loading();
      this.$http.post('api/accounts/linkAccounts/facebook', { accessToken: response.authResponse.accessToken })
        .then(response => {
          this.form.isFacebookRegistered = true;
          this.form.facebookName = response.data
          this.$vs.notify({
            title: 'Success',
            text: 'Facebook account linked, you may now use your facebook account to login',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 4000
          })
        })
        .catch(errors => {
          this.$vs.notify({
            title: 'Failed',
            text: `Unable to link facebook account`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        })
        .finally(()=> this.$vs.loading.close());

    },
    handleClickGetAuth () {
      this.$gAuth.getAuthCode()
      .then(authCode => {
        // On success
        return this.$http.post('http://your-backend-server.com/auth/google', { code: authCode, redirect_uri: 'postmessage' })
      })
      .then(response => {
        
        // And then
      })
      .catch(error => {
        console.log(error)
        // On fail do something
      })
    },
    handleGoogleSignIn () {
      this.$gAuth.signIn()
        .then(googleUser => {
          // On success do something, refer to https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid
          var id_token = googleUser.getAuthResponse().id_token
          this.google.isSignIn = this.$gAuth.isAuthorized
          if (this.$gAuth.isAuthorized && id_token){
            this.$vs.loading();
            this.$http.post('api/accounts/linkAccounts/google', { idToken: id_token })
              .then(response => {
                this.form.isGoogleRegistered = true;
                this.form.googleEmail = response.data
                this.$vs.notify({
                  title: 'Success',
                  text: 'Google account linked, you may now use your google account to login',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success',
                  time: 4000
                })
              })
              .catch(errors => {
                this.$vs.notify({
                  title: 'Failed',
                  text: `Unable to link google account`,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                });
              })
              .finally(()=> this.$vs.loading.close());
          }
        })
        .catch(error  => {
          // On fail do something
          console.log(error)
        })
    },
    handleGoogleSignOut () {
      this.$gAuth.signOut()
      .then(() => {
        // On success do something
        this.google.isSignIn = this.$gAuth.isAuthorized
      })
      .catch(error  => {
        // On fail do something
      })
    },
    handleUnlinkGoogleAccount () {
      this.$vs.loading();
      this.$http.delete('api/accounts/linkAccounts/google')
        .then(response => {
          this.form.isGoogleRegistered = false;
          this.form.googleEmail = '';
          this.$vs.notify({
            title: 'Success',
            text: 'Google account unlinked',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 4000
          })
        })
        .catch(errors => {
          this.$vs.notify({
            title: 'Failed',
            text: `Unable to unlink google account`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        })
        .finally(()=> this.$vs.loading.close());
    },
    handleTwitterSignIn() {
      this.$vs.loading();
      this.$http.post('api/accounts/linkAccounts/twitter')
        .then(response => {
          const windowFeatures =
            'toolbar=no, menubar=no, width=600, height=700';
          var childWindow = this.openPopupWindow(response.data, "Twitter Signin", window, 600, 700);
          childWindow.focus();
        })
        .catch(error => {
          this.$_notifyFailureByResponseData(error.response.data);
        })
        .finally(()=> this.$vs.loading.close());
    },
    handleUnlinkTwitterAccount () {
      this.$vs.loading();
      this.$http.delete('api/accounts/linkAccounts/twitter')
        .then(response => {
          this.form.isTwitterRegistered = false;
          this.form.twitterHandle = '';
          this.$_notifySuccess('Twitter account unlinked');
        })
        .catch(errors => {
          this.$_notifyFailureByResponseData(error.response.data);
        })
        .finally(()=> this.$vs.loading.close());
    },
    openPopupWindow (url, windowName, win, w, h) {
      const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
      const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
      return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }
  }

}
</script>