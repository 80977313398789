<template>
  <travio-center-container pageTitle="Account Settings" gridWidth="1/2">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model='selectedTabIndex' class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

          <!-- GENERAL -->
          <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'General Settings' : ''">
            <div class="tab-general md:ml-0 md:mt-0 mt-0 ml-0">
              <account-general-settings />
            </div>
          </vs-tab>

          <!-- PERSONAL INFO -->
          <vs-tab icon-pack="feather" icon="icon-info" :label="!isSmallerScreen ? 'Personal Info' : ''">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
              <account-personal-info />
            </div>
          </vs-tab>

          <!-- SECURITY -->
          <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Security' : ''">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
              <account-security />
            </div>
          </vs-tab>

          <!-- LINK ACCOUNTS -->
          <vs-tab icon-pack="feather" icon="icon-link-2" :label="!isSmallerScreen ? 'Link Accounts' : ''">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
              <account-linked-accounts />
            </div>
          </vs-tab>

          <!-- NOTIFICATIONS -->
          <vs-tab icon-pack="feather" icon="icon-bell" :label="!isSmallerScreen ? 'Notifications' : ''">
            <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
              <account-notifications />
            </div>
          </vs-tab>

        </vs-tabs>
      </div>
    </vx-card>
    
  </travio-center-container>

</template>

<script>
import AccountGeneralSettings from './AccountGeneralSettings.vue'
import AccountPersonalInfo from './AccountPersonalInfo.vue'
import AccountSecurity from './AccountSecurity.vue'
import AccountLinkedAccounts from './AccountLinkedAccounts.vue'
import AccountNotifications from './AccountNotifications.vue'
import TravioCenterContainer from '../../components/travio-pro/TravioCenterContainer.vue'

export default {
  components: {
    AccountGeneralSettings,
    AccountPersonalInfo,
    AccountSecurity,
    AccountLinkedAccounts,
    AccountNotifications,
    TravioCenterContainer
  },
  data () {
    return {
      selectedTabIndex: 0
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  created () {
    if (this.$route.query.startuptab) {
      this.selectedTabIndex = this.$route.query.startuptab
    }
      
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>