<template>
  <div class="mt-6 mb-base">
    <!-- Info -->
    <div class="mt-4">
      <label class="text-sm">Birth Date</label>
      <flat-pickr v-model="form.dob" :config="{ dateFormat: 'Y-m-d' }" class="w-full" />
    </div>

    <!-- Country -->
    <div class="mt-4">
      <label class="text-sm">Country</label>
      <v-select :value="selectedCountry" @input="setSelectedCountry" placeholder="Country" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
    </div>

    <!-- Locale -->
    <!-- <div class="mt-4">
      <label class="text-sm">Locale</label>
      <v-select v-model="form.locale" placeholder="Locale" :options="localeOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
    </div> -->

    <!-- Languages -->
    <div class="mt-4">
      <label class="text-sm">Spoken Languages</label>
      <v-select :value="selectedLanguages" @input="setSelectedLanguages" multiple :closeOnSelect="false" :options="langOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
    </div>

    <!-- Mobile Number -->
    <vs-input v-model="form.mobile" class="w-full mt-8" type="text" label-placeholder="Mobile" />

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button @click="onSubmit" class="ml-auto mt-4">Save Changes</vs-button>
      <!-- <vs-button class="ml-4 mt-2" type="border" color="warning">Reset</vs-button> -->
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  components: {
    flatPickr
  },
  data () {
    return {
      form: new Form({
        dob: null,
        countryCode: null,
        locale: "",
        spokenLanguages: null,
        mobile: "",
      }),
      selectedCountry: {},
      selectedLanguages: null,
      // selectedDob: null,
      countryOptions: [
        { label: 'Canada', code: 'CA' },
        { label: 'France', code: 'FR' },
        { label: 'Germany', code: 'DE' },
        { label: 'Portugal', code: 'PT' },
        { label: 'Spain', code: 'ES' },
        { label: 'United Kingdom', code: 'GB' },
        { label: 'United States', code: 'US' }
      ],
      langOptions: [
        { label: 'English',  code: 'english'  },
        { label: 'Spanish',  code: 'spanish'  },
        { label: 'French',   code: 'french'   },
        { label: 'Russian',  code: 'russian'  },
        { label: 'German',   code: 'german'   },
        { label: 'Arabic',   code: 'arabic'   },
        { label: 'Sanskrit', code: 'sanskrit' }
      ],
      localeOptions: [
        { label: 'English - United Kingdom',  code: 'en-GB' },
        { label: 'English - United States',  code: 'en-US' },
        { label: 'French',   code: 'fr-FR' }
      ]
    }
  },
  methods: {
    setSelectedCountry (value) {
      this.form.countryCode = value ? value.code : null
      this.selectedCountry = value;
    },
    setSelectedLanguages (value) {
      this.selectedLanguages = value;
      this.form.spokenLanguages = this.selectedLanguages.length 
        ? this.selectedLanguages.map((item) => item.code).join(',')
        : null
    },
    // onSelectedDobChange (selectedDates, dateStr, instance) {
    //   this.form.dob = selectedDates.length === 0 ? null : selectedDates[0];
    // },
    onSubmit () {
      this.$vs.loading();
      this.form.post('api/accounts/personalInfo')
        .then(response => {
          this.$vs.notify({
            title: 'Success',
            text: 'Changes had been saved',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        })
        .catch(errors => {
          this.$vs.notify({
            title: 'Failed',
            text: `Unable to save personal info`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        })
        .finally(()=> this.$vs.loading.close());
    },
  },
  mounted () {
    this.$http.get('api/accounts/personalInfo')
      .then(resp => {
        var model = resp.data
        this.form.dob = model.dob
        this.form.countryCode = model.countryCode
        this.form.spokenLanguages = model.spokenLanguages

        this.selectedCountry = this.countryOptions.find(x => x.code == model.countryCode) || {}

        const spokenLanguages = model.spokenLanguages ? model.spokenLanguages.split(',') : null;
        this.selectedLanguages = this.langOptions.filter(x => {
          return spokenLanguages && spokenLanguages.includes(x.code)
        });
        
        // this.locale = model.locale
        this.form.mobile = model.mobile
      })
      .catch(error => {
        console.locale(error)
      }) 
  }
}
</script>

<style>

</style>