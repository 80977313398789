<template>
  <div class="mt-4 mb-base">

    <div class="mb-base">
      <h6 class="mb-4">Activity</h6>

      <div class="flex items-center mb-4">
        <vs-switch v-model="taskReminder" />
        <span class="ml-4">Email me task reminders:</span>
      </div>
      <div class="flex items-center mb-4">
        <vs-switch v-model="taskAssigned" />
        <span class="ml-4">Email me when I get tasks assigned to me:</span>
      </div>
    </div>

    <div>
      <h6 class="mb-4">Application</h6>

      <div class="flex items-center mb-4">
        <vs-switch v-model="newsAndAnnouncement" />
        <span class="ml-4">News and announcements</span>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end mt-base">
      <vs-button @click="saveNotifications" class="ml-auto mt-2">Save Changes</vs-button>
      <!-- <vs-button class="ml-4 mt-2" type="border" color="warning">Reset</vs-button> -->
    </div>

  </div>
</template>

<script>
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
export default {
  mixins: [TpNotificationMixin],
  data () {
    return {
      taskReminder: false,
      taskAssigned: false,
      newsAndAnnouncement: false
    }
  },
  mounted () {
    this.$http.get('api/accounts/notifications')
    .then(response => {
      this.taskReminder = response.data.taskReminder
      this.taskAssigned = response.data.taskAssigned
      this.newsAndAnnouncement = response.data.newsAndAnnouncement
    })
    .catch(error => this.$_notifyFailureByResponseData(error.response.data))
  },
  methods: {
    saveNotifications () {
      this.$vs.loading();
      this.$http.post('api/accounts/notifications', {
        taskReminder: this.taskReminder,
        taskAssigned: this.taskAssigned,
        newsAndAnnouncement: this.newsAndAnnouncement
      })
      .then(response => {
        this.$_notifySuccess('Successfully saved notifications.')
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
      }
  }
}
</script>
