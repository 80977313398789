<template>
  <div class="mt-6 mb-base">
    
    <h6 class="mb-4">Change Password</h6>
    <vs-input 
      v-model="oldPassword"
      v-validate="'required'"
      class="w-full mt-8" 
      type="password"
      name="oldPassword"
      label-placeholder="Old Password"
    />
    <span class="text-danger text-sm">{{ errors.first('oldPassword') ? 'Old Password Required' : '' }}</span>

    <vs-input 
      v-model="newPassword"
      v-validate="'required'"
      class="w-full mt-8" 
      type="password"
      name="newPassword"
      label-placeholder="New Password"
    />
    <span class="text-danger text-sm">{{ errors.first('newPassword') ? 'New Password Required' : '' }}</span>

    <vs-input 
      v-model="confirmNewPassword"
      v-validate="'required'"
      class="w-full mt-8"
      type="password"
      name="confirmNewPassword"
      label-placeholder="Confirm Password"
    />
    <span class="text-danger text-sm">{{ errors.first('confirmNewPassword') ? 'Confirm Password Required' : ''}}</span>

    <div class="flex flex-wrap items-center justify-end">
      <vs-button @click="changePassword" class="ml-auto mt-2">Change Password</vs-button>
    </div>

    <h6 class="mt-8 mb-4">{{ $t('account.security.twoFactorHeading') }}</h6>
    <label class="text-sm">Enable two-factor authentication (2FA) on this account:</label>
    <vs-switch class="mt-2" v-model="twoFactorEnabled" />
    <vs-button class="mt-4" v-if="twoFactorEnabled && !twoFactorHasApp" v-on:click="generateTwoFactorCode">Setup 2FA Application</vs-button>
    <vs-alert v-if="twoFactorEnabled && twoFactorHasApp" icon-pack="feather" icon="icon-info" class="h-full my-4" color="success">
      <span>You have set up your authenticator application. </span>
      <a href="#" @click="generateTwoFactorCode" class="hover:underline">Update authenticator application</a>
      <span> or </span>
      <a href="#" @click="generateBackupCodes" class="hover:underline">Generate new backup codes</a>
    </vs-alert>

    <vs-prompt
        title="Setup Two Factor Authentication"
        buttons-hidden
        @cancel="clearTwoFactorFields"
        @close="clearTwoFactorFields"
        :active.sync="twoFactorPrompt">
        <div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <p>Use Google Authenticator or any authenticator app to scan this</p>
            </div>
            <img style="margin:auto" :src="twoFactorDataUrl" />
            <div class="vx-col w-full flex justify-center">
              <p class="mt-5">Or enter the key manually: </p>
            </div>
            <div class="vx-col w-full flex justify-center">
              <p>{{formattedTwoFactorDataKey}}</p>
            </div>
            <div class="vx-col w-full mt-3">
                <vs-input 
                  name="validationcode"
                  type="number"
                  label-placeholder="Enter verfication code"
                  class="w-full mt-5" placeholder="Verification code" 
                  v-model="twoFactorInputCode" 
                />
            </div>
            <div v-if='showInvalidCodeMsg' class="vx-col w-full mt-2">
                <span class="text-danger text-sm">Invalid verification code.</span>
            </div>
            
          </div>

          <vs-button @click="closeTwoFactorDialog" color="danger" type="flat" class="ml-auto mt-6">Cancel</vs-button>
          <vs-button @click="setupTwoFactorApp" class="float-right ml-auto mt-6">Submit</vs-button>

      
        </div>
    </vs-prompt>

    <vs-prompt
        title="Backup Codes"
        accept-text= "Done"
        cancel-text= "Close"
        :active.sync="backupCodePrompt">
        <div>

          <div class="vx-row">
            <div class="vx-col w-full mb-5">
              <p>List of backup codes. These are one-time used only, but you can always generate new ones.</p>
            </div>
            <div class="vx-col w-1/2">
              <ul>
                <li v-for="backupCode in backupCodes" :key="backupCode">{{backupCode}}</li>
              </ul>
            </div>
          </div>

      
        </div>
    </vs-prompt>
  </div>
</template>

<script>
import qrcode from  'qrcode'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'

export default {
  mixins: [TpNotificationMixin],
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      twoFactorEnabled: false,
      twoFactorHasApp: false,
      twoFactorPrompt: false,
      twoFactorInputCode: '',
      showInvalidCodeMsg: false,
      twoFactorSecret: '',
      twoFactorDataUrl: '',
      twoFactorDataKey: '',
      backupCodePrompt: false,
      backupCodes: [],
      hasLoaded: false
    }
  },
  computed: {
    formattedTwoFactorDataKey () {
      // Add spaces every 4 chars
      return [...this.twoFactorDataKey].map((d, i) => (i) % 4 == 0 ? ' ' + d : d).join('').trim()
    }
  },
  watch: {
    twoFactorEnabled (val) {

      // Ignore during initial loading
      if(!this.hasLoaded) return 
      
      this.$vs.loading();
      this.$http.put('api/accounts/security/twofactor', { enable: val })
        .then(response => {
          this.$_notifySuccess('2FA settings updated. Setup two factor app to complete this step.');
        })
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => this.$vs.loading.close())

    }
  },
  methods: {
    changePassword () {
      this.$validator.validateAll()
      .then(result => {
        if(result) {
          this.$vs.loading()
            this.$http.post('api/accounts/security/password', {
              oldPassword: this.oldPassword,
              newPassword: this.newPassword,
              confirmNewPassword: this.confirmNewPassword
            })
            .then(response => {
              this.$_notifySuccess('Password changed');
            })
            .catch(error => {
              this.$_notifyFailureByResponseData(error.response.data)
            })
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Please fill out all required fields.')
        }
      })
      .catch(error => this.$_notifyFailure('Please fill out all required fields.'))

      
    },
    
    generateTwoFactorCode () {
      this.$vs.loading();
      this.$http.post('api/accounts/security/twofactor/authenticatorKey')
        .then(response => {
          this.twoFactorDataUrl = response.data.dataUrl
          this.twoFactorDataKey = response.data.dataKey
          this.twoFactorSecret = response.data.secret
          this.twoFactorPrompt = true
        })
        .catch(error => this.$_notifyFailure(error.response.data.title))
        .finally(() => this.$vs.loading.close())
    },
    setupTwoFactorApp () {
      this.$vs.loading();
      // If auth code is verified this will enable TwoFactorHasApp in the user table, and will save the secret as credential
      this.$http.post('api/accounts/security/twofactor/verification', { authCode: this.twoFactorInputCode, secret: this.twoFactorSecret})
        .then(response => {
          this.twoFactorHasApp = true;
          this.twoFactorPrompt = false
          this.$_notifySuccess('Two factor authentication is now enabled.')
        })
        .catch(error => {
          this.showInvalidCodeMsg = true;
          this.$_notifyFailureByResponseData(error.response.data)
        })
        .finally(() => this.$vs.loading.close())   
    },
    generateBackupCodes () {
      this.$vs.loading();
      this.$http.get('api/accounts/security/twofactor/twofactorbackupcodes')
      .then(response => {
        this.backupCodes = response.data;
        this.backupCodePrompt = true;
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())

      
    },
    closeTwoFactorDialog () {
      this.clearTwoFactorFields()
      this.twoFactorPrompt = false;
    },
    clearTwoFactorFields () {
      this.twoFactorInputCode = ''
      this.twoFactorSecret = ''
      this.twoFactorDataUrl = ''
      this.showInvalidCodeMsg = false;
    }
  },
  mounted () {
    this.$http.get('api/accounts/security')
      .then(response => {
        this.twoFactorEnabled = response.data.twoFactorEnabled;
        this.twoFactorHasApp = response.data.twoFactorHasApp;
      })
      .catch(error => {
        error => this.$_notifyFailure(error.response.data.title)
      })
      .finally(() => this.hasLoaded = true)
  }
}
</script>