<template>
  <div class="mt-4 mb-base">
    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar :src="form.profilePictureUrl" @click="$refs.profilePictureInput.click()" size="70px" class="mr-4 mb-4" />
      <div>
        <input type="file" hidden id="profilePictureInput" ref="profilePictureInput" v-on:change="handleFileUpload"/>
        <vs-button @click="$refs.profilePictureInput.click()" class="mr-4 sm:mb-0 mb-2">Upload photo</vs-button>
        <vs-button @click="removeProfilePicture" type="border" color="danger">Remove</vs-button>
        <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
      </div>
    </div>

    <!-- Info -->
    <vs-input class="w-full mt-8" disabled label-placeholder="User Name" v-model="form.email"></vs-input>
    
    <div class="mt-4">
      <label class="text-sm">{{ $t('account.generalSettings.titleLabel') }}</label>
      <v-select :value="selectedTitle" @input="setSelectedTitle" :options="titleOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
    </div>

    <vs-input class="w-full mt-8" :label-placeholder="$t('account.generalSettings.firstNameLabel')" v-model="form.firstName"></vs-input>
    <vs-input class="w-full mt-8" :label-placeholder="$t('account.generalSettings.lastNameLabel')" v-model="form.lastName"></vs-input>
    <vs-input class="w-full mt-8" disabled :label-placeholder="$t('account.generalSettings.emailLabel')" v-model="form.email"></vs-input>    

    <div class="mt-4">
      <label class="text-sm">{{ $t('account.generalSettings.dateFormatLabel') }}</label>
      <v-select class="text-sm" v-model="form.dateFormat" :reduce="x=>x.code" :options="dateOptions" append-to-body />
    </div>

    <div class="mt-4">
      <label class="text-sm">Datetime Format</label>
      <v-select class="text-sm" v-model="form.dateTimeFormat" :reduce="x=>x.code" :options="dateTimeOptions" append-to-body />
    </div>

    <vs-alert v-if="!form.emailConfirmed" icon-pack="feather" icon="icon-info" class="h-full my-4" color="warning">
      <span>Your email is not confirmed. Please check your inbox or <a href="#" @click="sendEmailVerification" class="hover:underline">resend confirmation</a></span>
    </vs-alert>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button @click="onSubmit" class="ml-auto mt-4">{{ $t('account.generalSettings.saveBtnText') }}</vs-button>
      <!-- <vs-button class="ml-4 mt-2" type="border" color="warning">Reset</vs-button> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Form } from '@/core-modules/form-framework/Form.js'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'

export default {
  mixins: [TpNotificationMixin],
  data () {
    return { 
      form: new Form(),
      selectedTitle: {},
      file: '',
      titleOptions: [
        { label: 'Mr.', code: 1 },
        { label: 'Miss.', code: 2 },
        { label: 'Mrs.', code: 3 },
        { label: 'Ms.', code: 4 },
        { label: 'Dr.', code: 5 }
      ],
      dateOptions: [
        { label: 'd/M/yyyy', code: 'd/M/yyyy' },
        { label: 'M/d/yyyy', code: 'M/d/yyyy' },
        { label: 'd-M-yyyy', code: 'M-d-yyyy' },
        { label: 'M-d-yyyy', code: 'M-d-yyyy' },
      ],
      dateTimeOptions: [
        { label: 'd/M/yyyy h:mm AM,PM', code: 'd/M/yyyy h:mm a' },
        { label: 'M/d/yyyy h:mm AM,PM', code: 'M/d/yyyy h:mm a' },
        { label: 'd-M-yyyy h:mm AM,PM', code: 'd-M-yyyy h:mm a' },
        { label: 'M-d-yyyy h:mm AM,PM', code: 'M-d-yyyy h:mm a' },
      ],
    } 
  },
  computed: mapState({
    generalSettings: state => state.accountStore.generalSettings
  }),
  mounted () {
    this.$store.dispatch('accountStore/loadAccount')
      .then(response => {
        // this.form = Object.assign({}, this.form, new Form(this.generalSettings))
        this.form = new Form(this.generalSettings)
        this.selectedTitle = this.titleOptions.find(x => x.code == this.generalSettings.title) || {};
      });
  },
  methods: {
    handleFileUpload () {
      this.file = this.$refs.profilePictureInput.files[0];
      if (this.file) this.submitProfilePicture();
    },
    submitProfilePicture () {
      let formData = new FormData();
      formData.append('profilePictureInput', this.file);
      this.$store.dispatch('accountStore/uploadProfilePicture', formData )
        .then(response => {
          this.form.profilePictureUrl = response.data.profilePictureUrl;
          this.$_notifySuccess('Profile picture has been saved.');
        })
        .catch(error => {
          this.$_notifyFailureByResponseData(error.response.data)
        });
    },
    removeProfilePicture () {
      this.$refs.profilePictureInput.value = '';
      this.form.profilePictureUrl = '';
    },
    sendEmailVerification () {
      this.$vs.loading();
      this.form.post('api/accounts/generalSettings/emailVerification', { email: this.email })
        .then(response => {
          this.$_notifySuccess('Verification link has been sent to your email.');
        })
        .catch(error => {
          this.$_notifyFailureByResponseData(error.response.data)
        })
        .finally(()=> this.$vs.loading.close());
    },
    onSubmit () {
      this.$vs.loading();
      this.form.post('api/accounts/generalSettings')
        .then(response => {
          this.$_notifySuccess('Changes had been saved');
          this.$store.dispatch('updateUserInfoExplicitCompany', { companyId: this.$store.state.ActiveCompanyId})
        })
        .catch(error => {
          this.$_notifyFailureByResponseData(error.response.data)
        })
        .finally(()=> this.$vs.loading.close());
    },
    setSelectedTitle (value) {
      this.form.title = value ? value.code : 0
      this.selectedTitle = value;
    }
  }
}
</script>

<style>

</style>